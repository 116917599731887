import React, {useEffect, useState} from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import {Row, Col, Card, ListGroup, Spinner} from "react-bootstrap";
import {PostData} from "../../api_key/PostData";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import i18next from "i18next"
import { DirectionsRenderer, GoogleMap, InfoWindow, Marker, Polyline, LoadScript} from "@react-google-maps/api";

let loadInterval = false
let selectedDeviceData = []
let sessionStorage = []
let polyPath = []
let center = {}
const polylineStyle = {
    fillColor: "lightblue",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

const LiveTracking = (props) => {
    const [dataLoader, setDataLoader] = useState(true)
    const [deviceHistoryList, setDeviceHistoryList] = useState([])

    const [markers, setMarkers] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [polyline, setPolyline] = useState([]);
    const [directions, setDirections] = useState(null);

    useEffect(() => {
        if(localStorage.getItem('loginDetails') !== null) {
            sessionStorage = JSON.parse(localStorage.getItem('loginDetails'));
        }

        document.title = props.pageTitle
        props.loadFooterHeight()

        setTimeout(() => setDataLoader(false), 3000);
        const loadDevices = setInterval(() => loadAllDevices(), 5000);
        loadAllDevices()
        return () => clearInterval(loadDevices);
    }, []);

    const loadAllDevices = () => {
        PostData("/frontCall/getAllClientDevices")
            .then(result => {
                if (result.response.length > 0) {
                    let optionsValue = []
                    optionsValue.push(<ListGroup.Item as={"li"} onClick={(e) => {
                        polyPath = []
                        removeAllMarkers()
                        removeAllPolyline()
                        removeDirectionRenderer()

                        loadInterval = false

                        let deviceSelected = document.querySelectorAll(".timeline .list-group-item")
                        deviceSelected.forEach((deviceSelector) => {
                            deviceSelector.classList.remove("active-list-group")
                        })
                        e.currentTarget.classList.add("active-list-group")

                        let centerPosition = []
                        for (let i = 0; i < result.response.length; i++) {
                            let devicePath = result.response[i].deviceHistoryPath
                            if(result.response[i].deviceHistoryPath.length > 0) {
                                addMarker({
                                    lat: devicePath[devicePath.length - 1][devicePath[devicePath.length - 1].length - 1].lat,
                                    lng: devicePath[devicePath.length - 1][devicePath[devicePath.length - 1].length - 1].lng,
                                    created: devicePath[devicePath.length - 1][devicePath[devicePath.length - 1].length - 1].created,
                                    device_name: result.response[i].device_name,
                                    device_type: result.response[i].device_type,
                                    uniqueKey: "all_marker_" + i,
                                })
                                centerPosition.push({
                                    lat: devicePath[devicePath.length - 1][devicePath[devicePath.length - 1].length - 1].lat,
                                    lng: devicePath[devicePath.length - 1][devicePath[devicePath.length - 1].length - 1].lng,
                                })
                            }
                        }
                        center = {lat: centerPosition[0].lat, lng: centerPosition[0].lng}
                    }} key={"all_" + result.response[0].uuid}>
                        {i18next.t("all")}
                    </ListGroup.Item>)

                    for (let i = 0; i < result.response.length; i++) {
                        if (result.response[i].deviceHistoryPath.length > 0) {
                            optionsValue.push(<ListGroup.Item as={"li"} onClick={(e) => {
                                polyPath = []
                                removeAllMarkers()
                                removeAllPolyline()
                                removeDirectionRenderer()

                                selectedDeviceData = result.response[i]
                                loadInterval = true

                                let deviceSelected = document.querySelectorAll(".timeline .list-group-item")
                                deviceSelected.forEach((deviceSelector) => {
                                    deviceSelector.classList.remove("active-list-group")
                                })
                                e.currentTarget.classList.add("active-list-group")

                                let centerPosition = []
                                centerPosition.push({
                                    lat: result.response[i].deviceHistoryPath[0][0].lat,
                                    lng: result.response[i].deviceHistoryPath[0][0].lng,
                                })
                                for (let j = 0; j < result.response[i].deviceHistoryPath.length; j++) {
                                    for(let poly = 0; poly < result.response[i].deviceHistoryPath[j].length; poly++) {
                                        polyPath.push({
                                            lat: result.response[i].deviceHistoryPath[j][poly].lat,
                                            lng: result.response[i].deviceHistoryPath[j][poly].lng,
                                        })
                                    }
                                    addMarker({
                                        lat: result.response[i].deviceHistoryPath[j][0].lat,
                                        lng: result.response[i].deviceHistoryPath[j][0].lng,
                                        created: result.response[i].deviceHistoryPath[j][0].created,
                                        device_name: result.response[i].device_name,
                                        device_type: result.response[i].device_type,
                                        title: i18next.t("start"),
                                        uniqueKey: "device_marker_start" + j
                                    })
                                    addMarker({
                                        lat: result.response[i].deviceHistoryPath[j][result.response[i].deviceHistoryPath[j].length - 1].lat,
                                        lng: result.response[i].deviceHistoryPath[j][result.response[i].deviceHistoryPath[j].length - 1].lng,
                                        created: result.response[i].deviceHistoryPath[j][result.response[i].deviceHistoryPath[j].length - 1].created,
                                        device_name: result.response[i].device_name,
                                        device_type: result.response[i].device_type,
                                        title: i18next.t("end"),
                                        uniqueKey: "device_marker_end" + j
                                    })
                                }
                                addPolyline(polyPath, "device_polyline")

                                if(typeof result.response[i].promoter_route === 'object') {
                                    fetchDirectionsData(result.response[i])
                                } else {
                                    removeDirectionRenderer()
                                }

                                center = {lat: centerPosition[0].lat, lng: centerPosition[0].lng}
                            }} key={result.response[i].uuid}>
                                {result.response[i].device_name}
                            </ListGroup.Item>)
                        }
                    }
                    setDeviceHistoryList(optionsValue)
                } else {
                    let optionsValue = []
                    optionsValue.push(<p className="text-center">{i18next.t("no_devices_online")}</p>)
                    setDeviceHistoryList(optionsValue)
                }

                if (loadInterval) {
                    result.response.forEach((totalDevicesResult, index) => {
                        if(totalDevicesResult.uuid === selectedDeviceData.uuid) {
                            if(totalDevicesResult.deviceHistoryPath.length !== selectedDeviceData.deviceHistoryPath.length) {
                                selectedDeviceData = totalDevicesResult
                                addMarker({
                                    lat: totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1][totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1].length - 1].lat,
                                    lng: totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1][totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1].length - 1].lng,
                                    created: totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1][totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1].length - 1].created,
                                    device_name: totalDevicesResult.device_name,
                                    device_type: totalDevicesResult.device_type,
                                    title: i18next.t("end"),
                                    uniqueKey: "device_marker_end" + totalDevicesResult.deviceHistoryPath.length + totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1].length,
                                })
                                for(let poly = 0; poly < totalDevicesResult.deviceHistoryPath[index].length; poly++) {
                                    polyPath.push({
                                        lat: totalDevicesResult.deviceHistoryPath[index][poly].lat,
                                        lng: totalDevicesResult.deviceHistoryPath[index][poly].lng,
                                    })
                                }
                            }
                            for(let j = 0; j < totalDevicesResult.deviceHistoryPath.length; j++) {
                                if(totalDevicesResult.deviceHistoryPath[j].length !== selectedDeviceData.deviceHistoryPath[j].length) {
                                    selectedDeviceData = totalDevicesResult
                                    removeMarker(selectedDeviceData.deviceHistoryPath[j][selectedDeviceData.deviceHistoryPath[j].length - 1].uniqueKey)
                                    addMarker({
                                        lat: totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1][totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1].length - 1].lat,
                                        lng: totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1][totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1].length - 1].lng,
                                        created: totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1][totalDevicesResult.deviceHistoryPath[totalDevicesResult.deviceHistoryPath.length - 1].length - 1].created,
                                        device_name: totalDevicesResult.device_name,
                                        device_type: totalDevicesResult.device_type,
                                        title: i18next.t("end"),
                                        uniqueKey: selectedDeviceData.deviceHistoryPath[j][selectedDeviceData.deviceHistoryPath[j].length - 1].uniqueKey,
                                    })
                                    for(let poly = 0; poly < totalDevicesResult.deviceHistoryPath[j].length; poly++) {
                                        polyPath.push({
                                            lat: totalDevicesResult.deviceHistoryPath[j][poly].lat,
                                            lng: totalDevicesResult.deviceHistoryPath[j][poly].lng,
                                        })
                                    }
                                }
                            }
                            addPolyline(polyPath, "device_polyline")
                        }
                    })
                }
            })
    }

    // Function to add a marker
    const addMarker = (markerData) => {
        setMarkers((prevMarkers) => [
            ...prevMarkers,
            {
                lat: markerData.lat,
                lng: markerData.lng,
                created: markerData.created,
                device_name: markerData.device_name,
                device_type: markerData.device_type,
                title: markerData.title,
                uniqueKey: markerData.uniqueKey,
            },
        ]);
    };

    // Function to remove all markers
    const removeAllMarkers = () => {
        setMarkers([]); // Set the markers state to an empty array
    };

    // Function to remove a marker by uniqueKey
    const removeMarker = (markerKeyToRemove) => {
        setMarkers((prevMarkers) =>
            prevMarkers.filter((marker) => marker.uniqueKey !== markerKeyToRemove)
        );
    };

    // Function to add a polyline
    const addPolyline = (path, uniqueKey) => {
        setPolyline((prevPolyline) => [
            ...prevPolyline,
            {
                path,
                uniqueKey,
            },
        ]);
    };

    // Function to remove all polyline
    const removeAllPolyline = () => {
        setPolyline([]);
    };

    // Fetch directions data, e.g., using the DirectionsService
    const fetchDirectionsData = (deviceData) => {
        const directionsService = new window.google.maps.DirectionsService();
        let wayPts = [];
        for (const element of deviceData.promoter_route.path_array) {
            wayPts.push({
                location: {lat: element.latitude, lng: element.longitude},
                stopover: true
            });
        }

        const request = {
            origin: {
                lat: deviceData.promoter_route.path_array[0].latitude,
                lng: deviceData.promoter_route.path_array[0].longitude
            },
            destination: {
                lat: deviceData.promoter_route.path_array[deviceData.promoter_route.path_array.length - 1].latitude,
                lng: deviceData.promoter_route.path_array[deviceData.promoter_route.path_array.length - 1].longitude
            },
            waypoints: wayPts,
            optimizeWaypoints: false,
            travelMode: window.google.maps.TravelMode.WALKING, // You can change the travel mode as needed
        };

        directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                addDirectionRenderer(result); // Pass the result to the function
            } else {
                console.log(result)
                console.log(status)
                console.log(request)
            }
        });
    };

    // Function to add direction renderer
    const addDirectionRenderer = (directions) => {
        setDirections(directions);
    };

    // Function to remove direction renderer
    const removeDirectionRenderer = () => {
        setDirections(null);
    };

    return (
        <>
            {dataLoader &&
                <div className="api-data-loader">
                    <Spinner animation="grow" variant="info"/>
                </div>}

            <Header headerTitlePage={props.headerTitle} logoutUser={props.userLogout}/>
            <Sidebar permissionList={props.permissionList}/>
            <div className="content-body" id="content-body">
                <div className="container-fluid h-100">
                    <Row className="h-100">
                        <Col xs={12} lg={4} className="h-100">
                            <Card>
                                <Card.Body>
                                    <PerfectScrollbar>
                                        <ListGroup as={"ul"} className="timeline overflow-hidden">
                                            {deviceHistoryList}
                                        </ListGroup>
                                    </PerfectScrollbar>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} lg={8} className="h-100">
                            <Card>
                                <Card.Body>
                                    <div className="mapouter h-100">
                                        <div className="gmap_canvas h-100">
                                            <LoadScript googleMapsApiKey="AIzaSyBG7TCobbomGm8tGu01XFKtBGVJzHRruRQ">
                                                <GoogleMap
                                                    mapContainerStyle={{width: '100%', height: '100%', minHeight: '250px'}}
                                                    center={center}
                                                    zoom={15}
                                                >
                                                    {markers.map((marker) => (<>
                                                        <Marker
                                                            key={marker.uniqueKey}
                                                            position={{ lat: marker.lat, lng: marker.lng }}
                                                            onClick={() => setSelectedMarker(marker)}
                                                        />
                                                    </>))}

                                                    {selectedMarker && (<>
                                                        <InfoWindow
                                                            position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                                                            onCloseClick={() => setSelectedMarker(null)}
                                                        >
                                                            <ListGroup as={"ul"} className="timeline overflow-hidden pl-1">
                                                                <ListGroup.Item as={"li"}>
                                                                    <span className="font-weight-bold">{i18next.t("device_name_map")}: </span> {selectedMarker.device_name}
                                                                </ListGroup.Item>
                                                                {<ListGroup.Item as={"li"}>
                                                                    <span className="font-weight-bold">{i18next.t("device_type_map")}: </span>
                                                                    {parseInt(selectedMarker.device_type) === 1 ? i18next.t("android_screen")
                                                                        : parseInt(selectedMarker.device_type) === 2 ? i18next.t("android_phone")
                                                                            : ""}
                                                                </ListGroup.Item>}
                                                                <ListGroup.Item as={"li"}>
                                                                    <span className="font-weight-bold">{i18next.t("time")}: </span> {selectedMarker.created}
                                                                </ListGroup.Item>
                                                                {loadInterval && <ListGroup.Item as={"li"}>
                                                                    <span className="font-weight-bold">{i18next.t("device_position")}: </span> {selectedMarker.title}
                                                                </ListGroup.Item>}
                                                            </ListGroup>
                                                        </InfoWindow>
                                                    </>)}

                                                    {polyline.map((polyline) => (<>
                                                        <Polyline
                                                            key={polyline.uniqueKey}
                                                            path={polyline.path}
                                                            options={polylineStyle}
                                                        />
                                                    </>))}

                                                    {directions && <DirectionsRenderer directions={directions} />}
                                                </GoogleMap>
                                            </LoadScript>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default LiveTracking;
