import React from 'react'
import './style.css';
import $ from "jquery";
import i18next from "i18next"
import {NavLink, Link} from "react-router-dom";
import {Button, Form, Modal} from "react-bootstrap";
import Select from "react-select";
import {PostData} from "../api_key/PostData";
import {Helmet} from "react-helmet";

class Home extends React.Component {
    constructor(props) {
        super(props);
        let cityCookie = {}
        if (localStorage.getItem('selectedCity')) {
            cityCookie = JSON.parse(localStorage.getItem('selectedCity'));
        }
        this.state = {
            cityCookieValue: cityCookie,
            chooseCityModal: false,
            zipcodeOptions: [],
            zipcodeSelected: "",
            zipcodeErr: "",
        }
        this.chooseCity = this.chooseCity.bind(this)
    }

    componentDidMount() {
        let bodyTag = $("body")
        bodyTag.addClass("dark-page").removeClass("light-page")
        $("#main-sections").attr("style", "padding-top: " + (document.getElementById("sticky-top").offsetHeight + 16) + "px")
        $(".header-bar").removeClass("active-nav")
        let style = window.getComputedStyle(document.querySelector(".container"));
        $("#sticky-top").removeClass("sticky-top")
        $(".burger-menu").addClass("pointer-opacity")

        PostData("/frontCall/getAllCities")
            .then(result => {
                this.setState({
                    zipcodeOptions: result.response.map(data => ({label: data.name, value: data.uuid})),
                    allCities: result.response
                })
            })
    }

    chooseCity() {
        if (Object.keys(this.state.cityData).length === 0) {
            this.setState({zipcodeErr: i18next.t("enter_zipcode")})
        } else {
            this.setState({
                chooseCityModal: false,
                zipcodeSelected: [],
                zipcodeErr: "",
            })
            this.setCityCookie(this.state.cityData)
        }
    }

    setCityCookie(userLocation) {
        const selectedCityValue = '{' +
            '"contact_us_email":"' + userLocation.contact_us_email + '",' +
            '"contact_us_phone":"' + userLocation.contact_us_phone + '",' +
            '"latitude":"' + userLocation.latitude + '",' +
            '"longitude":"' + userLocation.longitude + '",' +
            '"name":"' + userLocation.name + '",' +
            '"uuid":"' + userLocation.uuid + '",' +
            '"zipcode":"' + userLocation.zipcode + '"' +
            '}';
        localStorage.setItem('selectedCity', selectedCityValue);
        window.location.reload()
    }

    render() {
        return (<>
            <Helmet>
                <title>{this.props.pageTitle}</title>
                <meta name="description"
                      content="Backpack Media предлагает инновационную городскую рекламу на рюкзаках с Full HD экранами. Покажем вашу рекламу везде, где угодно."/>
            </Helmet>
            <section className="section dark-logo-section container">
                <span className="language-change-button m-0">
                    <button className={(i18next.language === "ru") ? "ru-button page-link active" : "ru-button page-link"} onClick={() => {
                        document.cookie = 'lang=ru; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                        window.location.reload()
                    }}>RU</button>
                    <button className={(i18next.language === "en") ? "eng-button page-link active" : "eng-button page-link"} onClick={() => {
                        document.cookie = 'lang=en; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure';
                        window.location.reload()
                    }}>ENG</button>
                </span>
                <div className="logo-and-menu">
                    <Link to={"/"} variant={"btn"} className="logo-image bg-transparent">
                        <img src="/assets/web-images/logo-white.svg" alt="Section Logo"/>
                    </Link>
                    <h1 style={{width: 0, height: 0, overflow: "hidden"}}>{i18next.t("home_page_h1")}</h1>
                    <ul className="navigation-menu">
                        <li className="nav-item">
                            <NavLink to={"about"} className="about_advertising_format nav-link">{i18next.t("about_advertising")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"tariffs"} className="tariffs nav-link">{i18next.t("tariffs")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"promoter"} className="to_the_promoters nav-link">{i18next.t("home_promoters")}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={"contacts"} className="contacts nav-link">{i18next.t("contacts")}</NavLink>
                        </li>
                    </ul>
                </div>
                <span className="header-right-nav d-flex align-items-center text-white mb-2" onClick={() => {
                    this.setState({
                        chooseCityModal: true,
                        zipcodeSelected: Object.keys(this.state.cityCookieValue).length > 0
                            ? {label: this.state.cityCookieValue.name, value: this.state.cityCookieValue.uuid}
                            : []
                    })
                }}>
                    <i className="flaticon-381-location-2 mr-2" style={{fontSize: 20}}></i>
                    {Object.keys(this.state.cityCookieValue).length ? this.state.cityCookieValue.name : ""}
                </span>
                <Link to={"/login"} target={"_blank"} className="header-right-nav d-flex">
                    <span className="user-login-icon mr-2">
                        <img src="/assets/web-images/icon-user-dark.svg" alt="User Login" className="dark-user-icon"/>
                        <img src="/assets/web-images/icon-user-white.svg" alt="User Login" className="white-user-icon"/>
                    </span>
                    <span className="personal-area text-white">{i18next.t("personal_area")}</span>
                </Link>
            </section>
            <Modal show={this.state.chooseCityModal} centered backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{i18next.t("please_choose_your_city")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="zipcode">
                        <Select defaultValue={this.state.zipcodeSelected}
                                onChange={(zipcodeSelected) => {
                                    this.setState({
                                        zipcodeSelected: zipcodeSelected,
                                        cityData: this.state.allCities.filter(data => data.uuid === zipcodeSelected.value)[0]
                                    })
                                }}
                                options={this.state.zipcodeOptions}
                        />
                        {this.state.zipcodeErr &&
                            <div className="text-danger pl-3 pt-1">{this.state.zipcodeErr}</div>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        this.setState({chooseCityModal: false, zipcode: "", zipcodeErr: "",})
                    }}>{i18next.t("close")}</Button>
                    <Button variant={"primary"} onClick={this.chooseCity}>{i18next.t("confirm")}</Button>
                </Modal.Footer>
            </Modal>
        </>)
    }
}

export default Home;